.button {
    --color--button: var(--color--base);
    --color--hover: var(--color--accent);

    display: inline-block;
    position: relative;
    font-weight: 700;
    color: var(--color--button);
    transition: all 0.35s var(--easing--in-out);

    @media (hover: hover) {
        &:hover {
            --color--button: var(--color--hover);

            &::after {
                --size: 17px;
                right: -5px;
            }
        }
    }

    &::after {
        --size: 13px;
        content: '';
        display: block;
        width: var(--size);
        height: var(--size);
        position: absolute;
        top: 0;
        right: 0;
        background: var(--color--button);
        transform: translate(100%, -100%);
        transition: all 0.35s var(--easing--in-out);
    }
}

