header {
    --header--height: 50px;
    position: sticky;
    top: 0;
    z-index: 9999;
    background: var(--color--light);
    border-bottom: 1px solid var(--color--accent);

    @media (--media--md) {
        --header--height: 90px;
    }

    div.header--inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: var(--header--height);

        .logo {
            a {
                img {
                    display: block;
                    width: auto;
                    height: 35px;

                    @media (--media--md) {
                        height: 50px;
                    }
                }
            }
        }
    }
}
