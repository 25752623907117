.listitem {
    list-style-type: none;

    .listitem--link {
        display: inline-block;
        position: relative;
        z-index: 10;
        hyphens: auto;
        word-break: break-word;
        color: var(--color--light);
        cursor: pointer;

        @media (hover: hover) {
            &:hover {
                color: var(--color--accent);
            }
        }
    }
}

.navigation--listitem {
    .listitem--link-container {
        cursor: pointer;

        @media (--media--lg-max) {
            display: grid;
            grid-template-columns: max-content 1fr;

            .navigation--listitem--link {
                font-size: var(--text--lg);
                grid-column: 1 / 2;
            }

            .dropdown {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                grid-column: 2 / 3;

                svg {
                    display: block;
                    width: 20px;
                    height: auto;
                    transition: transform 0.5s var(--easing--in-out);
                    fill: none;
                    stroke: var(--color--base);
                    stroke-width: 4;
                    stroke-miterlimit: 42.1397;
                }
            }
        }

        .navigation--listitem--link {
            font-family: var(--copy);
            font-size: var(--text--sm);
            line-height: var(--leading--normal);
            font-weight: 400;
            text-transform: uppercase;
            color: var(--color--base);
        }

        .dropdown {
            display: none;
        }
    }

    .subnavigation {
        padding-top: var(--spacing--md);

        @media (--media--lg-max) {
            display: grid;
            grid-template-rows: 0fr;
            padding-top: 0;
            transition: grid-template-rows 0.5s var(--easing--in-out),
                        padding-top 0.5s var(--easing--in-out);
        }

        .subnavigation--list {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            gap: var(--spacing--md);
        }
    }

    &.active {
        .navigation--listitem--link {
            font-weight: 700;
            color: var(--color--accent);
        }
    }

    &.open {
        svg {
            transform: rotate(180deg);
        }

        div.subnavigation {
            grid-template-rows: 1fr;
            padding-top: var(--spacing--md);
        }
    }
}
