div.button--container {
    .button--collection {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        gap: var(--spacing--xl);
        row-gap: var(--spacing--lg);
    }

    &.fc--light {

        .button {
            --color--button: var(--color--light) !important;
            --color--hover: var(--color--light) !important;
        }
    }
}