.stagemedia--slide {
    display: grid !important;
    position: relative;
    background: var(--color--base);
    overflow: hidden;

    picture,
    video,
    img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 0;
        object-fit: cover;
        object-position: center center;
        inset: 0;
    }

    .text {
        grid-area: main;
        max-width: 1000px;
        position: relative;
        z-index: 2;
        padding-left: var(--spacing--lg);
        margin-top: 30dvh;
        margin-bottom: var(--spacing--2xl);

        &::before {
            --size: 20px;
            content: '';
            width: var(--size);
            height: var(--size);
            position: absolute;
            top: 10px;
            left: calc(var(--size) * -0.5);
            z-index: 2;
            background: var(--color--light);

            @media (--media--md) {
                --size: 30px;
                top: 14px;
            }
        }
    }

    &::before {
        content: '';
        display: block;
        grid-column-start: main-start;
        width: 1px;
        height: 100%;
        position: absolute;
        z-index: 2;
        background: var(--color--light);
    }

    &::after {
        content: '';
        display: block;
        grid-area: page;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        background: linear-gradient(10deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 100%);
        mix-blend-mode: multiply;
    }
}
