.cards {
    --el--column-count: 1;

    @media (--media--md) {
        &.md\:columns-2 {
            --el--column-count: 2;
        }
    }
    @media (--media--lg) {
        &.lg\:columns-3 {
            --el--column-count: 3;
        }
    }
    @media (--media--xl) {
        &.xl\:columns-4 {
            --el--column-count: 4;
        }
    }

    .card--collection {
        display: grid;
        grid-template-columns: repeat(var(--el--column-count), 1fr);
        grid-template-rows: auto;
        align-items: stretch;
        gap: var(--spacing--column);
    }
}
