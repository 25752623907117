nav.navigation--header {
    @media (--media--lg-max) {
        height: 0;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: var(--color--light);    
        overflow-x: hidden;
        transition: height 0.5s var(--easing--out);

        &.active {
            height: calc(100dvh - var(--header--height));
            min-height: fit-content;
            overflow-y: auto;
            border-bottom: 1px solid var(--color--accent);

            ul.navigation--list {
                overflow-y: scroll;
            }
        }
    }

/* Navigation */
    ul.navigation--list {
        display: flex;
        grid-area: main;
        grid-row-start: 1;
        flex-direction: column;
        padding: var(--spacing--xl) 0;
        gap: var(--spacing--lg);

        @media (--media--xl) {
            grid-area: page;
            flex-direction: row;
        }
    }
}
