div, h1, h2, h3, h4, h5, h6 {
    &.headline {
        font-family: var(--headline);
        font-weight: 700;
        text-transform: uppercase;
        hyphens: auto;
        word-break: break-word;

        span:not(.nbsp, .shy) {
            display: block;
        }
    }
}

body.neos-backend {
    div, h1, h2, h3, h4, h5, h6 {
        &.headline {
            text-transform: none !important;
        }
    }
}
