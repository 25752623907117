div.paragraph {
    hyphens: auto;
    word-break: break-word;

    &.fc--light {
        ul {
            li {
                &::before {
                    background: var(--color--light);
                }
            }
        }

        ol {
            li {
                &::before {
                    color: var(--color--light);
                }
            }
        }
    }

    ul {
        li {
            list-style-type: none;
            position: relative;
            padding-left: var(--spacing--sm);

            &::before {
                content: '';
                width: 10px;
                height: 2px;
                position: absolute;
                top: calc(var(--text--sm) * 0.75);
                left: 0;
                background: var(--color--base);
            }
        }
    }

    ol {
		list-style-type: none;
		counter-reset: number;

        li {
            position: relative;
            padding-left: var(--spacing--md);

			&::before {
				content: counter(number)'.';
                position: absolute;
                left: 0;
                z-index: 1;
				font-size: var(--text--sm);
				line-height: var(--leading--loose);
                font-weight: 700;
				color: var(--color--base);
				counter-increment: number;
			}
        }
    }

    a {
        display: inline-block;
        position: relative;
        text-decoration: underline;

        @media (hover: hover) {
            &:hover {
                color: var(--color--accent);
            }
        }
    }
}
