/* font-size text */
.text--xs {
    font-size: var(--text--xs);
    line-height: var(--leading--normal);
}
.text--sm {
    font-size: var(--text--sm);
    line-height: var(--leading--loose);
}
.text--md {
    font-size: var(--text--md);
    line-height: var(--leading--normal);
}
.text--lg {
    font-size: var(--text--lg);
    line-height: var(--leading--normal);
}
.text--xl {
    font-size: var(--text--xl);
    line-height: var(--leading--normal);
}
.text--2xl {
    font-size: var(--text--2xl);
    line-height: var(--leading--normal);
}
