.section {
    --outside-width: var(--spacing--sm);
    --main-width: 0;
    --small-width: 1fr;

    display: grid;
    grid-template-columns:
                    [page-start]
                        var(--outside-width)
                        [main-start]
                            var(--main-width)
                            [small-start]
                                var(--small-width)
                                [center-left]
                                    calc(var(--spacing--column) * 0.5)
                                    [center]
                                    calc(var(--spacing--column) * 0.5)
                                [center-right]
                                var(--small-width)
                            [small-end]
                            var(--main-width)
                        [main-end]
                        var(--outside-width)
                    [page-end];
    grid-auto-rows: auto;

    @media (--media--sm) {
        --outside-width: var(--spacing--column);
    }

    @media (--media--lg) {
        --outside-width: 1fr;
        --main-width: 1fr;
        --small-width: 4fr;
    }

    @media (--media--xl) {
        --small-width: 2fr;
    }

    @media (--media--2xl) {
        --outside-width: 2fr;
        --main-width: 1.25fr;
    }

    .grid-area-\[site\] {
        grid-area: site;
    }
    .grid-area-\[page\] {
        grid-area: page;
    }
    .grid-area-\[main\] {
        grid-area: main;
    }
    .grid-area-\[small\] {
        grid-area: small;
    }
}

.main--collection {
    .section {
        &:nth-child(1) {
            &.margin-top--xl {
                margin-top: var(--spacing--lg) !important;
            }
        }
    }
}
