.section--cards {
    grid-template-rows: max-content min-content max-content;

    .text, .buttons {
        grid-column-start: main-start;
        grid-column-end: main-end;
    }

    .cards {
        grid-area: main;
        grid-row-start: 3 !important;
    }
}
