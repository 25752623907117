/* Padding xs */
.padding-top--xs {
    padding-top: var(--spacing--xs) !important;
}
.padding-bottom--xs {
    padding-bottom: var(--spacing--xs) !important;
}

/* Padding sm */
.padding-top--sm {
    padding-top: var(--spacing--sm) !important;
}
.padding-bottom--sm {
    padding-bottom: var(--spacing--sm) !important;
}

/* Padding md */
.padding-top--md {
    padding-top: var(--spacing--md) !important;
}
.padding-bottom--md {
    padding-bottom: var(--spacing--md) !important;
}

/* Padding lg */
.padding-top--lg {
    padding-top: var(--spacing--lg) !important;
}
.padding-bottom--lg {
    padding-bottom: var(--spacing--lg) !important;
}

/* Padding xl */
.padding-top--xl {
    padding-top: var(--spacing--xl) !important;
}
.padding-bottom--xl {
    padding-bottom: var(--spacing--xl) !important;
}

/* padding 2xl */
.padding-top--2xl {
    padding-top: var(--spacing--2xl) !important;
}
.padding-bottom--2xl {
    padding-bottom: var(--spacing--2xl) !important;
}

/* padding 3xl */
.padding-top--3xl {
    padding-top: var(--spacing--3xl) !important;
}
.padding-bottom--3xl {
    padding-bottom: var(--spacing--3xl) !important;
}

/* padding 4xl */
.padding-top--4xl {
    padding-top: var(--spacing--4xl) !important;
}
.padding-bottom--4xl {
    padding-bottom: var(--spacing--4xl) !important;
}
