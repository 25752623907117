html {
	font-size: var(--base-size);
	scroll-behavior: smooth;
	scroll-padding-top: var(--spacing--3xl);
}


body {
    max-width: 3000px;
	font-family: var(--copy);
	font-size: var(--base-size);
    line-height: var(--leading--loose);
	font-weight: 500;
	color: var(--color--base);
	background: var(--color--light);
	overflow-x: hidden;
	overflow-y: scroll;
    margin-inline: auto;

    @media (--media--md-max) {
        &.no-scroll {
            overflow-y: hidden !important;
        }
    }
}

span.sr-only {
    width: 0;
    height: 0;
    position: absolute;
    overflow: hidden;
}


.neos-backend {
	.neos-contentcollection {
		& > div {
			width: 100%;
		}

        & > [class*=addEmptyContentCollectionOverlay] {
            height: auto;
            min-height: 30px;
            outline-style: dashed;

            &::before {
                content: "Please add your child items here.";
                display: block;
                color: #adadad;
                padding: var(--spacing--xs);
            }
        }
	}
}

::selection {
	text-shadow: none;
	color: var(--color--light);
	background: var(--color--base);
}

.bg--base {
    ::selection {
        text-shadow: none;
        color: var(--color--base);
        background: var(--color--light);
    }
}
