:root {
    /* colors */
    --color--accent: #4E802F;
    --color--light: #fff;
    --color--grey: magenta;
    --color--base: #889396;

    /* typography */
    --headline: 'Eurostile Extended', sans-serif;
    --copy: 'Helvetica LT Std', sans-serif;
    --base-size: 16px;

    /* text-size*/
    --text--xs: 0.8rem;
    --text--sm: 1rem;
    --text--md: 1.2rem;
    --text--lg: 1.35rem;
    --text--xl: 1.5rem;
    --text--2xl: 1.75rem;

    /* leading / line-height */
    --leading--none: 1;
    --leading--tight: 1.15;
    --leading--normal: 1.3;
    --leading--loose: 1.6;

    /* spacing */
    --spacing--xs: 0.5rem; /* 9px */
    --spacing--sm: 1rem; /* 18px */
    --spacing--md: 1.5rem; /* 27px */
    --spacing--lg: 2rem; /* 36px */
    --spacing--xl: 3rem; /* 54px */
    --spacing--2xl: 4rem;
    --spacing--3xl: 6rem;
    --spacing--4xl: 8rem;

    /* grid */
    --spacing--column: var(--spacing--sm);

    /* easing */
    --easing--in-out: cubic-bezier(0.785, 0.135, 0.15, 0.86);
    --easing--in: cubic-bezier(0.6, 0.04, 0.98, 0.335);
    --easing--out: cubic-bezier(0.075, 0.82, 0.165, 1);

    @media (--media--sm) {
        --base-size: 18px;
        --spacing--2xl: 5.5rem; /* 99px */
        --spacing--3xl: 8.5rem; /* 153px */
        --spacing--4xl: 11rem; /* 198px */
    }

    @media (--media--md) {
        --spacing--column: var(--spacing--xl);
        --text--xl: 1.65rem; /* 40px */
        --text--2xl: 2.5rem; /* 60px */
    }
}

/* breakpoints*/

@custom-media --media--sm (min-width: 576px);
@custom-media --media--md (min-width: 768px);
@custom-media --media--lg (min-width: 1024px);
@custom-media --media--xl (min-width: 1300px);
@custom-media --media--2xl (min-width: 1800px);

@custom-media --media--sm-max (max-width: 767px);
@custom-media --media--md-max (max-width: 1023px);
@custom-media --media--lg-max (max-width: 1299px);
@custom-media --media--xl-max (max-width: 1799px);
