.section--stagemedia {
    position: relative;
    background: var(--color--accent);
    overflow: hidden;

    .swiper-pagination-container {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 5;
        z-index: 10;
        margin-bottom: var(--spacing--md);
        pointer-events: none;
        touch-action: none;
        align-self: end;

        .swiper-navigation {
            grid-area: main;
        }

        .swiper-pagination {
            width: 100%;

            @media (--media--lg) {
                justify-content: center;
            }
        }
    }
}
