footer {
    grid-template-rows: min-content min-content;
    grid-row-gap: var(--spacing--xl);

    .footer--inner {
        display: grid;
        grid-area: main;
        grid-row-start: 1;
        grid-row-end: 2;
        grid-row-gap: var(--spacing--xl);

        @media (--media--lg) {
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: var(--spacing--column);

            .paragraph {
                &.--first {
                    grid-area: 1 / 1 /2 / 2;
                }
                &.--second {
                    grid-area: 1 / 2 / 2 / 3;
                }
            }
            .navigation--footer {
                grid-area: 1 / 3 / 2 / 4;
            }
        }

        .paragraph {
            strong {
                display: inline-block;
            }
        }
    
        .navigation--footer {
            display: flex;
    
            ul.footer--list {
                width: fit-content;
    
                li.footer--listitem {
                    list-style-type: none;
                    display: block;
                    margin-bottom: var(--spacing--xs);
    
                    a {
                        display: inline-block;
                        position: relative;
                        font-weight: 700;
    
                        @media (hover: hover) {
                            &:hover {
                                color: var(--color--accent);
                            }
                        }
                    }
    
                    &.active {
                        a {
                            color: var(--color--accent);
                        }
                    }
                }
            }
        }
    }

    .footer--bottom {
        grid-area: main;
        grid-row-start: 2;
        grid-row-end: 3;
    }
}
