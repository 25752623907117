@font-face {
     font-style: normal;
     font-family: 'Helvetica LT Std';
     font-weight: 700;
     src: url('../Fonts/HelveticaLTStd-Bold.eot');
     src: url('../Fonts/HelveticaLTStd-Bold.eot?#iefix') format('embedded-opentype'),
         url('../Fonts/HelveticaLTStd-Bold.woff2') format('woff2'),
         url('../Fonts/HelveticaLTStd-Bold.woff') format('woff'),
         url('../Fonts/HelveticaLTStd-Bold.ttf') format('truetype'),
         url('../Fonts/HelveticaLTStd-Bold.svg#HelveticaLTStd-Bold') format('svg');
     font-display: swap;
 }
 
 @font-face {
     font-style: normal;
     font-family: 'Helvetica LT Std';
     font-weight: 400;
     src: url('../Fonts/HelveticaLTStd-Roman.eot');
     src: url('../Fonts/HelveticaLTStd-Roman.eot?#iefix') format('embedded-opentype'),
         url('../Fonts/HelveticaLTStd-Roman.woff2') format('woff2'),
         url('../Fonts/HelveticaLTStd-Roman.woff') format('woff'),
         url('../Fonts/HelveticaLTStd-Roman.ttf') format('truetype'),
         url('../Fonts/HelveticaLTStd-Roman.svg#HelveticaLTStd-Roman') format('svg');
     font-display: swap;
 }

 @font-face {
     font-style: normal;
     font-family: 'Eurostile Extended';
     font-weight: 900;
     src: url('../Fonts/EurostileExtended-Black.eot');
     src: url('../Fonts/EurostileExtended-Black.eot?#iefix') format('embedded-opentype'),
         url('../Fonts/EurostileExtended-Black.woff2') format('woff2'),
         url('../Fonts/EurostileExtended-Black.woff') format('woff'),
         url('../Fonts/EurostileExtended-Black.ttf') format('truetype'),
         url('../Fonts/EurostileExtended-Black.svg#EurostileExtended-Black') format('svg');
     font-display: swap;
 }