.media {
    height: auto !important;

    @media (--media--xl) {
        picture {
            position: absolute;
            inset: 0;
        }
    }

    .video,
    video,
    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}
