.swiper-container {
    .swiper-wrapper {
        z-index: 0;

        @media (--media--lg-max) {
            height: auto;
        }
    }
    .swiper-pagination-container {
        .swiper-navigation {
            display: flex;
            grid-row-start: 1 !important;
            gap: var(--spacing--lg);

            .swiper-pagination {
                --size: 20px;
                display: flex;
                pointer-events: none;
                gap: var(--spacing--sm);
                touch-action: none;

                @media (--media--lg) {
                    --size: 30px;
                }

                .swiper-pagination-bullet {
                    display: inline-block;
                    width: var(--size);
                    height: var(--size);
                    background: var(--color--light);
                    cursor: pointer;
                    pointer-events: auto;
                    transform: translateY(-50%);
                    touch-action: auto;

                    &.swiper-pagination-bullet-active {
                        transform: translateY(-50%) scale(1.25);
                    }
                }
            }

            .swiper-navigation-prev,
            .swiper-navigation-next {
                display: block;
                cursor: pointer;
                transform: scale(1);
                transition: transform 0.35s var(--easing--in-out);

                @media (hover: hover) {
                    &:hover {
                        transform: scale(1.1);
                    }
                }

                svg {
                    display: inline-block;
                    width: auto;
                    height: 25px;
                    stroke: var(--color--accent);
                    stroke-width: 5px;
                    fill: none;

                    @media (--medial--lg) {
                        height: 35px;
                    }
                }

                &.swiper-button-disabled {
                    cursor: default;

                    @media (hover: hover) {
                        &:hover {
                            transform: scale(1);
                        }
                    }
                    svg {
                        opacity: 0.25;
                    }
                }
            }
        }
    }
}
