/* margin xs */
.margin-top--xs {
    margin-top: var(--spacing--xs) !important;
}
.margin-bottom--xs {
    margin-bottom: var(--spacing--xs) !important;
}

/* margin sm */
.margin-top--sm {
    margin-top: var(--spacing--sm) !important;
}
.margin-bottom--sm {
    margin-bottom: var(--spacing--sm) !important;
}

/* margin md */
.margin-top--md {
    margin-top: var(--spacing--md) !important;
}
.margin-bottom--md {
    margin-bottom: var(--spacing--md) !important;
}

/* margin lg */
.margin-top--lg {
    margin-top: var(--spacing--lg) !important;
}
.margin-bottom--lg {
    margin-bottom: var(--spacing--lg) !important;
}

/* margin xl */
.margin-top--xl {
    margin-top: var(--spacing--xl) !important;
}
.margin-bottom--xl {
    margin-bottom: var(--spacing--xl) !important;
}

/* margin 2xl */
.margin-top--2xl {
    margin-top: var(--spacing--2xl) !important;
}
.margin-bottom--2xl {
    margin-bottom: var(--spacing--2xl) !important;
}

/* margin 3xl */
.margin-top--3xl {
    margin-top: var(--spacing--3xl) !important;
}
.margin-bottom--3xl {
    margin-bottom: var(--spacing--3xl) !important;
}

/* margin 4xl */
.margin-top--4xl {
    margin-top: var(--spacing--4xl) !important;
}
.margin-bottom--4xl {
    margin-bottom: var(--spacing--4xl) !important;
}
