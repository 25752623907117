.card {
    position: relative;
    border: 1px solid var(--color--accent);

    @media (--media--md) {
        figure.image--content {
            --size: 50px;
        }

        &:nth-of-type(3n-2) {
            figure.image--content {
                bottom: -25px;
                right: 10%;
            }
        }
        &:nth-of-type(3n-1) {
            figure.image--content {
                top: -25px;
                left: 40%;
            }
        }
        &:nth-of-type(3n) {
            figure.image--content {
                top: 25%;
                right: -25px;
            }
        }
    }



    @media (--media--sm-max) {
        padding: calc(var(--spacing--column) * 2);
        padding-top: calc(var(--spacing--column) * 2 + 10px);        
        margin-top: 20px;
        
        figure.image--content {
            top: -20px;
            left: calc(var(--spacing--column) * 2);
        }
    }

    @media (--media--lg-max) {
        padding: var(--spacing--column);
        padding-inline: calc(var(--spacing--column) * 0.5);
    }

    @media (--media--xl) {
        padding: var(--spacing--column);
    }
    
    figure.image--content {
        --size: 40px;
        width: var(--size);
        height: var(--size);
        position: absolute;
        z-index: 0;
        background: var(--color--accent);
        aspect-ratio: 1 / 1;

        img {
            display: block;
            width: 100%;
            height: 100%;
            z-index: 0;
            object-fit: contain;
            object-position: center center;
        }
    }

    h3.headline {
        font-family: var(--copy);
        text-transform: none !important;
    }
}