.section--textmedia {
    align-items: center;

    @media (--media--xl) {
        .media--content {
            grid-row-start: 1;
            min-height: 700px;

            .swiper-container {
                position: absolute;
                inset: 0;
            }

            .swiper-pagination-container {
                height: var(--spacing--2xl);
            }
        }
        .text--content {
            grid-row-start: 1;
            margin: var(--spacing--2xl) 0;
        }

        &.media--right {
            .text--content {
                grid-column-start: main-start;
                grid-column-end: center-left;
                padding-right: var(--spacing--lg);
            }

            .media--content {
                grid-column-start: center;
                grid-column-end: page-end;
            }
        }

        &.media--left {
            .media--content {
                grid-column-start: page-start;
                grid-column-end: center;
            }

            .text--content {
                grid-column-start: center-right;
                grid-column-end: main-end;
                padding-left: var(--spacing--lg);
            }
        }
    }

    @media (--media--2xl) {
        .media--content {
            min-height: 900px;
        }
    }

    &.media--right,
    &.media--left {
        .media--content {
            grid-area: page;
            grid-row-start: 1;
        }
    }

    .text--content {
        grid-area: main;
        grid-row-start: 2;
        margin: var(--spacing--xl) 0;
        align-self: center;
    }

    .media--content {
        grid-row-start: 1;
        position: relative;
        overflow: hidden;
        align-self: stretch;

        .swiper-pagination-container {
            height: var(--spacing--xl);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 5;
            pointer-events: none;
            touch-action: none;

            .swiper-navigation {
                border-top: 1px solid var(--color--light);
            }
        }
    }
    


    &.media--right {
        .swiper-pagination-container {
            .swiper-navigation {
                grid-column-start: page-start;
                grid-column-end: center;
                justify-content: flex-end;
                padding-right: var(--spacing--lg);
                border-right: 1px solid var(--color--light);
            }
        }
    }

    &.media--left {
        .swiper-pagination-container {
            .swiper-navigation {
                grid-column-start: center;
                grid-column-end: page-end;
                padding-left: var(--spacing--lg);
                border-left: 1px solid var(--color--light);
            }
        }
    }
}

@media (--media--xl) {
    .section--textmedia.spacing--dependent + .section--textmedia.spacing--dependent {
        margin-top: 0 !important;
    }
    .section--textmedia + .section--textmedia.spacing--dependent {
        margin-top: 0 !important;
    }
}